import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core'
import { mainColors } from '../../../styles/createMyTheme'
import { Add, Clear } from '@material-ui/icons'
import OperationStore from '../../../stores/OperationStore'
import { makeStyles } from '@material-ui/core/styles'
import { Stores } from '../../../stores'
import {
  newConfirmation,
  OperationStates,
} from '../../../interfaces/IOperation'
import QuantitySelector from './QuantitySelector'
import KeyPad from './KeyPad'
import AuthenticationStore from '../../../stores/AuthenticationStore'

interface ReworkDialogProps {
  operationStore?: OperationStore
  authenticationStore?: AuthenticationStore
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.60em',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  button: {
    marginBottom: '20px',
    background: mainColors.greenGrad,
    width: '90%',
    alignSelf: 'center',
    color: 'white',
  },
  caption: {
    color: mainColors.mediumGrey,
  },
}))

const ReworkDialog: React.FunctionComponent<any> = inject(
  Stores.operationStore,
  Stores.authenticationStore
)(
  observer((props: ReworkDialogProps) => {
    const { operationStore, authenticationStore } = props
    const [confirmation, setConfirmation] = useState(newConfirmation)
    const [keypadOpen, setKeypadOpen] = useState(false)
    const classes = useStyles()

    return (
      <React.Fragment>
        <Button
          onClick={() => {
            operationStore.changeOperationType(OperationStates.partlyConfirmed)
            operationStore.closeDialogs()
          }}
          className={classes.button}
          variant={'contained'}>
          <Typography variant={'h6'}>RÜCKMELDEN</Typography>
        </Button>
        <KeyPad
          open={keypadOpen}
          close={() => setKeypadOpen(false)}
          number={confirmation.rework}
          setNumber={setManNumber}
        />
        <Paper className={classes.root}>
          <Grid
            container
            justify={'center'}
            alignItems={'center'}
            direction={'column'}>
            <Grid
              container
              alignItems={'center'}
              justify={'space-between'}
              direction={'row'}>
              <Typography variant={'h5'}>RÜCKMELDUNG</Typography>
              <IconButton onClick={() => operationStore.closeDialogs()}>
                <Clear />
              </IconButton>
            </Grid>

            <Grid item style={{ width: '100%' }}>
              <Grid
                container
                justify={'space-between'}
                alignItems={'center'}
                direction={'row'}>
                <Grid item>
                  <Grid
                    container
                    direction={'row'}
                    justify={'space-between'}
                    alignItems={'center'}
                    spacing={1}>
                    <Grid item>
                      <Add
                        fontSize={'large'}
                        style={{ color: mainColors.blue }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant={'caption'}>Nacharbeit</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <QuantitySelector
                  quantity={confirmation.rework}
                  add={addRework}
                  subtract={subtractRework}
                  openKeypad={() => {
                    setKeypadOpen(true)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          alignItems={'center'}
          justify={'center'}
          direction={'row'}
          style={{ backgroundColor: mainColors.ultraLightGrey }}>
          <Grid item>
            <Switch
              checked={authenticationStore.stayLoggedIn}
              onChange={(e: any) =>
                authenticationStore.handleLoginSelectorToggle()
              }
              color={'primary'}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.caption} variant={'caption'}>
              Angemeldet bleiben
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )

    function addRework() {
      const newConfirmation = confirmation
      setConfirmation({ ...newConfirmation, rework: confirmation.rework + 1 })
    }

    function subtractRework() {
      const newConfirmation = confirmation
      setConfirmation({
        ...newConfirmation,
        rework: newConfirmation.rework > 0 ? newConfirmation.rework - 1 : 0,
      })
    }

    function setManNumber(newNumber: number) {
      setConfirmation({ ...confirmation, rework: newNumber })
    }
  })
)

export default ReworkDialog
