import RootStore from './RootStore'
import { action, observable } from 'mobx'
import {
  IConfirmation,
  IOperation,
  OperationStates,
} from '../interfaces/IOperation'
import mockData from '../Utils/MockData/data'
import moment from 'moment'
import _ from 'lodash'

interface ITabManager {
  confirmation: boolean
  rework: boolean
  pause: boolean
}
const initialTabManager: ITabManager = {
  confirmation: false,
  rework: false,
  pause: false,
}
export default class OperationStore {
  rootStore: RootStore
  @observable listOfOperations: IOperation[] = mockData.operations
  @observable tabManager: ITabManager = initialTabManager
  @observable currentOperation: IOperation | undefined = undefined
  @observable isDetailsView: boolean = false
  @observable scanningValid: boolean = true

  @observable currentOperationType: OperationStates | undefined = undefined

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action setCurrentOperation(operation?: IOperation) {
    this.currentOperation = { ...operation }
    operation
      ? this.setDetailsType(operation.__state as OperationStates)
      : this.setDetailsType()
  }

  @action openDetailsView(operation: IOperation) {
    this.isDetailsView = true
    this.setCurrentOperation(operation)
    this.setDetailsType(operation.__state as OperationStates)
  }

  @action closeDetailsView() {
    this.isDetailsView = false
    this.setCurrentOperation()
    this.setDetailsType()
  }

  @action setDetailsType(type?: OperationStates) {
    this.currentOperationType = type ? type : undefined
  }

  @action openMessage(type: OperationStates | 'pause') {
    switch (type) {
      case OperationStates.inRework:
        this.rootStore.snackbarStore.openSnackbar(
          'Nacharbeit erfolgreich gestartet.',
          'success'
        )
        break
      case OperationStates.inProgress:
        this.rootStore.snackbarStore.openSnackbar(
          'Arbeit erfolgreich gestartet.',
          'success'
        )
        break
      case OperationStates.inSetup:
        this.rootStore.snackbarStore.openSnackbar(
          'Rüsten erfolgreich gestartet.',
          'success'
        )
        break
      case OperationStates.confirmed:
        this.rootStore.snackbarStore.openSnackbar(
          'Finale Rückmeldung erfolgreich.',
          'success'
        )
        break
      case OperationStates.partlyConfirmed:
        this.rootStore.snackbarStore.openSnackbar(
          'Rückmeldung erfolgreich.',
          'success'
        )
        break
      case 'pause':
        this.rootStore.snackbarStore.openSnackbar(
          'Vorgang erfolgreich unterbrochen.',
          'success'
        )
        break
    }
  }

  @action changeOperationType(type: OperationStates, pause?: boolean) {
    this.currentOperationType = type
    this.currentOperation = { ...this.currentOperation, __state: type }
    this.listOfOperations = this.changeOperationInList(
      this.currentOperation.confirmationNumber,
      type
    )
    if (pause) this.openMessage('pause')
    else this.openMessage(type)
    const stayLogged = this.rootStore.authenticationStore.stayLoggedIn
    if (!stayLogged) this.rootStore.authenticationStore.startAutoLogOff()
  }

  @action changeOperationInList(number: string, type: OperationStates) {
    let tempList = [...this.listOfOperations]
    tempList.forEach((operation: IOperation) => {
      if (operation.confirmationNumber === number) {
        operation.__state = type
        operation.lastUpdate = moment().unix()
      }
    })
    const orderedList = _.orderBy(tempList, 'lastUpdate', 'desc')
    return orderedList
  }

  @action isCurrentOperationOpen() {
    return (
      this.currentOperationType === OperationStates.open ||
      this.currentOperationType === OperationStates.confirmed ||
      this.currentOperationType === OperationStates.partlyConfirmed ||
      this.currentOperationType === undefined
    )
  }

  @action addConfirmation(confirmation: IConfirmation) {
    let tempConfirmation = { ...confirmation }
    tempConfirmation.start = moment().format('DD-MM-YYYY HH:mm')
    const newOperation = { ...this.currentOperation }
    newOperation.confirmations.push(tempConfirmation)
    this.currentOperation = { ...newOperation }
    const newList = [...this.listOfOperations]
    newList.forEach((operation: IOperation) => {
      if (
        operation.confirmationNumber ===
        this.currentOperation.confirmationNumber
      )
        operation.confirmations = this.currentOperation.confirmations
    })
    if (confirmation.finalConfirmation)
      this.changeOperationType(OperationStates.confirmed)
    else this.changeOperationType(OperationStates.partlyConfirmed)
  }

  @action searchScannedConfirmation(id: string, scan?: boolean) {
    const matchedOperations = this.listOfOperations.filter(
      (operation: IOperation) => operation.confirmationNumber === id
    )
    if (matchedOperations.length > 0) {
      this.rootStore.snackbarStore.openSnackbar(
        `Rückmeldecode ${matchedOperations[0].confirmationNumber} erkannt.`,
        'success'
      )
      if (scan) {
        this.rootStore.playSuccessSound()
        this.scanningValid = true
      }
      this.openDetailsView(matchedOperations[0])
    } else {
      if (scan) {
        this.rootStore.playErrorSound()
        this.scanningValid = false
      }
      this.rootStore.snackbarStore.openSnackbar(
        `Rückmeldecode wurde nicht erkannt.`,
        'error'
      )
    }
  }

  @action getSummedConfirmationYield() {
    let sum = 0
    this.currentOperation.confirmations.forEach(
      (confirmation) => (sum = sum + confirmation.yield)
    )
    return sum
  }

  @action getSummedConfirmationScrap() {
    let sum = 0
    this.currentOperation.confirmations.forEach(
      (confirmation) => (sum = sum + confirmation.scrap)
    )
    return sum
  }

  @action getMostRecentConfirmation() {
    return this.currentOperation.confirmations[
      this.currentOperation.confirmations.length - 1
    ]
  }

  @action openConfirmation() {
    if (!this.tabManager.confirmation) {
      this.tabManager = { ...initialTabManager, confirmation: true }
    }
  }

  @action openRework() {
    if (!this.tabManager.rework) {
      this.tabManager = { ...initialTabManager, rework: true }
    }
  }
  @action openPause() {
    if (!this.tabManager.rework) {
      this.tabManager = { ...initialTabManager, pause: true }
    }
  }

  @action closeDialogs() {
    this.tabManager = { ...initialTabManager }
  }
}
