import React from 'react'
import HeaderBar from 'components/HeaderBar/HeaderBar'
import styles from './Home.module.scss'
import ProductionList from '../components/ProductionList/ProductionList'
import { makeStyles } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import { Stores } from '../stores'
import OperationStore from '../stores/OperationStore'
import ProductionDetails from '../components/ProductionDetails/ProductionDetails'
import Scanning from '../components/Scanning/Scanning'
import LogoutDialog from '../components/LogoutDialog/LogoutDialog'
import { Dialog } from '@material-ui/core'
import ConfirmationDialog from '../components/ProductionDetails/ActionDrawerDetails/ConfirmationDialog'
import ReworkDialog from '../components/ProductionDetails/ActionDrawerDetails/ReworkDialog'
import PauseDialog from '../components/ProductionDetails/ActionDrawerDetails/PauseDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
  },
  dialogPaper: {
    background: 'transparent',
    justifyContent: 'flex-end',
  },
}))

interface HomeProps {
  operationStore?: OperationStore
}

const Home: React.FunctionComponent<any> = inject(Stores.operationStore)(
  observer((props: HomeProps) => {
    const { operationStore } = props
    const classes = useStyles()
    return (
      <div className={`${styles.HomeContainer} ${classes.root}`}>
        <LogoutDialog />
        <HeaderBar />
        {operationStore.isDetailsView ? <ProductionDetails /> : <Scanning />}
        {/*    // Action buttons

       {operationStore.isDetailsView &&
          operationStore.currentOperationType !== OperationStates.confirmed && (
            <ActionDrawerDetails />
          )}*/}
        {!operationStore.isDetailsView && <ProductionList />}
        <Dialog
          onBackdropClick={closeDialogs}
          onClose={closeDialogs}
          fullScreen
          classes={{ paper: classes.dialogPaper }}
          open={operationStore.tabManager.confirmation}>
          <ConfirmationDialog />
        </Dialog>
        <Dialog
          onBackdropClick={closeDialogs}
          onClose={closeDialogs}
          fullScreen
          classes={{ paper: classes.dialogPaper }}
          open={operationStore.tabManager.pause}>
          <PauseDialog />
        </Dialog>
        <Dialog
          onBackdropClick={closeDialogs}
          onClose={closeDialogs}
          fullScreen
          classes={{ paper: classes.dialogPaper }}
          open={operationStore.tabManager.rework}>
          <ReworkDialog />
        </Dialog>
      </div>
    )

    function closeDialogs() {
      operationStore.closeDialogs()
    }
  })
)
export default Home
