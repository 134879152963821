import React, { useState } from 'react'
import {
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from '@material-ui/core'
import { mainColors } from '../../styles/createMyTheme'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import mockData from '../../Utils/MockData/data'
import { IOperation } from '../../interfaces/IOperation'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../stores'
import OperationStore from '../../stores/OperationStore'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90vw',
    maxHeight: '60vh',
    padding: '1em',
    position: 'absolute',
    bottom: '0%',
    right: '0%',
    left: '5%',
  },
  counter: {
    color: mainColors.lightGrey,
    fontSize: '16px',
  },
  arrow: {
    color: mainColors.lightGrey,
  },
  list: {
    overflowY: 'auto',
    width: '100%',
    anchor: 'bottom',
  },
  listItem: {
    marginTop: '.5em',
    padding: 0,
  },
  numberContainer: {
    borderRadius: '3px',
    display: 'flex',
    backgroundColor: mainColors.ultraLightGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    marginLeft: '.25em',
    marginRight: '.25em',
  },
  itemStatus: {
    fontSize: '15px',
  },
  itemName: {
    fontSize: '18px',
  },
  lastUpdate: {
    fontSize: '.75em',
    color: mainColors.mediumGrey,
  },
}))

interface ProductionListProps {
  productionList: IOperation[]
  operationStore?: OperationStore
}

const mockOperations: IOperation[] = mockData.operations
const ProductionList: React.FunctionComponent<any> = inject(
  Stores.operationStore
)(
  observer((props: ProductionListProps) => {
    const { operationStore } = props
    const { listOfOperations = mockOperations } = operationStore
    const [drawerOpen, setDrawer] = useState(false)
    const classes = useStyles()
    const { t } = useTranslation()

    return (
      <Paper className={classes.root}>
        <Grid
          onClick={() => setDrawer(!drawerOpen)}
          container
          alignItems={'center'}
          justify={'space-between'}
          direction={'row'}>
          <Grid item>
            <Typography variant={'h5'}>Aktuelle Vorgänge</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              justify={'center'}
              alignItems={'center'}
              direction={'row'}
              spacing={1}>
              <Grid item>
                <Typography className={classes.counter} variant={'caption'}>
                  {listOfOperations.length}
                </Typography>
              </Grid>
              <Grid item>
                {drawerOpen ? (
                  <KeyboardArrowDown
                    fontSize={'large'}
                    className={classes.arrow}
                  />
                ) : (
                  <KeyboardArrowUp
                    fontSize={'large'}
                    className={classes.arrow}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {drawerOpen && (
          <List
            className={classes.list}
            onClick={() => setDrawer(!drawerOpen)}
            onKeyDown={() => setDrawer(!drawerOpen)}>
            {listOfOperations.map((production: IOperation, index: number) =>
              getListItem(production, index, listOfOperations.length)
            )}
          </List>
        )}
      </Paper>
    )

    function getListItem(item: IOperation, index: number, listLength: number) {
      return (
        <React.Fragment key={index}>
          <ListItem
            className={classes.listItem}
            button
            onClick={(e: any) => {
              operationStore.openDetailsView(item)
            }}>
            <Grid container alignItems={'center'} direction={'column'}>
              <Grid
                container
                alignItems={'center'}
                justify={'space-between'}
                direction={'row'}>
                <div className={classes.numberContainer}>
                  <Typography className={classes.number} variant={'caption'}>
                    {item.number}
                  </Typography>
                </div>
                <Typography className={classes.itemStatus}>
                  {t(item.__state).toUpperCase()}
                </Typography>
              </Grid>
              <Grid
                container
                alignItems={'center'}
                justify={'space-between'}
                direction={'row'}>
                <Typography className={classes.itemName}>
                  {getItemTitle(item.order.materialName)}
                </Typography>
                <Typography className={classes.lastUpdate}>
                  {getTimeDifference(item.lastUpdate)}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          {index < listLength - 1 && <Divider />}
        </React.Fragment>
      )
    }

    function getTimeDifference(timeString: number) {
      const now = moment()
      const time = moment.unix(timeString)
      const timeDiff =
        now.diff(time, 'days') > 0
          ? now.diff(time, 'days') + ' Tage'
          : now.diff(time, 'hours') > 0
          ? now.diff(time, 'hours') + ' Stunden'
          : now.diff(time, 'minutes') > 0
          ? now.diff(time, 'minutes') + ' Minuten'
          : now.diff(time, 'seconds') + ' Sekunden'

      return `vor ${timeDiff}`
    }

    function getItemTitle(content: string) {
      const reducedContent =
        content.length > 25 ? content.substring(0, 24) + '...' : content
      return reducedContent
    }
  })
)
export default ProductionList
