import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { mainColors } from '../../../styles/createMyTheme'

interface KeyPadProps {
  classes: any
  open: boolean
  close: () => void
  number: number
  setNumber: (newNum: number) => void
}
const dialogStyles = (props) => ({
  dialogPaper: {
    minHeight: '60vh',
    maxHeight: '85vh',
  },
})
const useStyles = makeStyles((theme) => ({
  root: {},
  keyPad: {
    borderRadius: '6px',
    width: '4em',
    margin: '.4em',
    backgroundColor: 'grey',
  },
  textField: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '20px',
  },
  input: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '20px',
  },
}))

const keypadKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',']
const KeyPad: React.FunctionComponent<any> = (props: KeyPadProps) => {
  const { open, close, classes, setNumber } = props
  const styles = useStyles()
  const [stringNumber, setStringNumber] = useState('0')
  useEffect(() => {
    setStringNumber('0')
  }, [open])
  useEffect(() => {
    if (stringNumber[0] === '0' && stringNumber.length > 1)
      setStringNumber(stringNumber.substring(1))
    if (stringNumber.includes('.'))
      setStringNumber(stringNumber.replace('.', ','))
  }, [stringNumber])

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={close}
      open={open}
      maxWidth={'xl'}
      onBackdropClick={close}>
      <Grid
        container
        justify={'center'}
        alignItems={'center'}
        direction={'column'}>
        <Grid item>
          <DialogTitle>Menegeneingabe</DialogTitle>
        </Grid>
        <Grid item>
          <Grid
            container
            justify={'center'}
            alignItems={'center'}
            spacing={3}
            direction={'column'}>
            <Grid item>
              <TextField
                value={stringNumber}
                className={styles.textField}
                inputProps={{
                  className: styles.input,
                }}
              />
            </Grid>
            <Grid item>
              <Typography>ST</Typography>
            </Grid>
            <Grid
              item
              container
              direction={'row'}
              justify={'flex-end'}
              alignItems={'center'}
              sm={4}
              spacing={3}
              style={{ width: '80%', paddingRight: '2em' }}>
              {keypadKeys.map(getKey)}
            </Grid>
            <Grid
              item
              container
              direction={'row'}
              justify={'flex-end'}
              style={{ width: '80%' }}>
              <Grid item>
                <Button
                  variant={'text'}
                  onClick={close}
                  style={{ color: mainColors.mediumGrey }}>
                  ABBRECHEN
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={'text'}
                  onClick={acceptAndClose}
                  style={{ color: mainColors.blue }}>
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )

  function getKey(key: string) {
    return (
      <Grid
        key={key + '-key-pad'}
        item
        className={styles.keyPad}
        style={{
          backgroundColor:
            key !== ',' ? mainColors.darkBlue : mainColors.lightGrey,
        }}
        onClick={() => {
          if (!(stringNumber.includes(',') && key === ','))
            setStringNumber(stringNumber + key)
        }}>
        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item>
            <Typography
              variant={'h5'}
              style={{
                color: key !== ',' ? mainColors.white : mainColors.black,
              }}>
              {key}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  function convertNumber() {
    if (stringNumber.includes(',')) {
      const tempStringNumber = stringNumber.replace(',', '.')
      setNumber(parseFloat(tempStringNumber))
    } else setNumber(parseInt(stringNumber))
  }

  function acceptAndClose() {
    convertNumber()
    close()
  }
}

export default withStyles(dialogStyles)(KeyPad)
