import React from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { AddCircle, RemoveCircle } from '@material-ui/icons'
import { mainColors } from '../../../styles/createMyTheme'

interface QuantitySelectorProps {
  quantity: number
  add: () => void
  subtract: () => void
  openKeypad: () => void
  negative?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: mainColors.ultraLightGrey,
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
  },
  middleSection: {
    width: '4em',
    borderLeft: `solid 1px ${mainColors.lightGrey}`,
    borderRight: `solid 1px ${mainColors.lightGrey}`,
  },
  counter: {
    fontWeight: 'bold',
    fontSize: '1.3em',
  },
  counterBlue: {
    color: mainColors.blue,
    fontWeight: 'bold',
    fontSize: '1.3em',
  },
  counterRed: {
    color: mainColors.error,
    fontWeight: 'bold',
    fontSize: '1.3em',
  },
}))
const QuantitySelector: React.FunctionComponent<any> = (
  props: QuantitySelectorProps
) => {
  const { quantity, add, subtract, openKeypad, negative } = props
  const classes = useStyles()
  return (
    <Grid className={classes.root} item>
      <Grid container alignItems={'center'} direction={'row'}>
        <Grid item>
          <IconButton onClick={subtract}>
            <RemoveCircle />
          </IconButton>
        </Grid>
        <Grid item className={classes.middleSection} onClick={openKeypad}>
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justify={'center'}>
            <Grid item>
              <Typography
                className={
                  quantity > 0
                    ? !negative
                      ? classes.counterBlue
                      : classes.counterRed
                    : classes.counter
                }>
                {quantity % 1 !== 0 ? convertDecimalToString() : quantity}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'caption'}>ST</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify={'center'} alignItems={'center'}>
            <Grid item>
              <IconButton onClick={add}>
                <AddCircle />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  function convertDecimalToString() {
    return `${quantity}`.replace('.', ',')
  }
}

export default QuantitySelector
