import pkg from '../../../package.json'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../stores'
import AuthenticationStore from '../../stores/AuthenticationStore'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import styles from './Login.module.scss'
import { mainColors } from '../../styles/createMyTheme'
import { withStyles } from '@material-ui/styles'
import HuebnerLogo from '../../assets/icons/hubner.svg'
import ScanLogin from '../../assets/icons/scan-icon-login.svg'
import { useSnackbar } from 'notistack'

interface LoginProps {
  authenticationStore?: AuthenticationStore
  navigator: any
}

interface IPin {
  [key: number]: string
}

const DELETE_KEY_CODE = 8
const $intervals = {
  checkFocus: null,
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  imageLarge: {
    width: '128px',
    height: '128px',
  },
  image: {
    width: '64px',
    height: '64px',
  },
  inputSection: {
    marginTop: '1em',
    height: '35vh !important',
    flexWrap: 'nowrap',
  },
  pinBox: {
    backgroundColor: 'transparent',
    margin: '0px',
  },
  button: {},
}))

const PinBox = withStyles({
  root: {
    backgroundColor: 'transparent',
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField)

const Login: React.FunctionComponent<any> = inject(Stores.authenticationStore)(
  observer((props: LoginProps) => {
    const { authenticationStore } = props
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const scanRef = useRef(null)
    const confirmButton = useRef(null)
    const pinInputs = [useRef(null), useRef(null), useRef(null), useRef(null)]
    const [id, setID] = useState('')
    const initialPin: IPin = {
      1: '',
      2: '',
      3: '',
      4: '',
    }
    const [scanOn, setScanOn] = useState<boolean>(true)
    const pinReducer = (state: IPin = initialPin, action: any) => {
      switch (action.type) {
        case 'reset': {
          return initialPin
        }
        default: {
          return Object.entries(initialPin).filter(
            (key) => key[0] === action.type
          ).length > 0
            ? { ...state, [`${action.type}`]: action.value }
            : state
        }
      }
    }
    const [pin, dispatch] = useReducer(pinReducer, initialPin)
    const classes = useStyles()
    const inputFilled = isInputFilled()

    useEffect(() => {})

    useEffect(() => {
      if (id) handleLogin()
      if (authenticationStore.scanningValid === false) setID('')
      // eslint-disable-next-line
    }, [id, authenticationStore.scanningValid])

    useEffect(() => {
      if (scanOn) {
        clearInterval($intervals.checkFocus)
        $intervals.checkFocus = setInterval(() => {
          if (scanRef.current) {
            if (scanRef.current !== document.activeElement) {
              scanRef.current.focus()
            }
          }
        }, 100)
      } else if (pinInputs[0].current) {
        clearInterval($intervals.checkFocus)
        pinInputs[0].current.focus()
      }
      // eslint-disable-next-line
    }, [scanOn])
    useEffect(() => {
      if (inputFilled && confirmButton.current) {
        confirmButton.current.focus()
        handleLogin()
      }
      // eslint-disable-next-line
    }, [inputFilled])

    return (
      <Grid
        className={`${classes.root} ${styles.gridContainer}`}
        container
        component="main">
        <div className={`${styles.divContainer}`}>
          <Box display="inline">
            <Typography
              style={{
                fontSize: '10px',
                color: `${mainColors.white}`,
                opacity: 0.5,
                marginBottom: '0.5em',
              }}>{`Version ${pkg.version}`}</Typography>
          </Box>
          <Typography
            variant="h5"
            style={{ color: `${mainColors.white}`, marginBottom: '1em' }}>
            ANMELDUNG
          </Typography>
          <img
            alt="Hübner Logo"
            className={classes.imageLarge}
            src={HuebnerLogo}
          />
          <input
            id={'scanning-id-input'}
            ref={scanRef}
            style={{ opacity: '0', height: '1px' }}
            value={id}
            onChange={(e) => {
              setID(e.target.value)
            }}
          />
          {scanOn ? DefaultSection() : ManualOptionSection()}
        </div>
      </Grid>
    )

    function DefaultSection() {
      return (
        <Grid
          className={`${classes.inputSection}`}
          container
          justify="flex-start"
          direction="column"
          alignItems="center">
          <Box position="relative">
            <div className={`${styles.diode}`}>
              <div className={`${styles.laser}`}></div>
            </div>
            <img alt="Scan Icon" className={classes.image} src={ScanLogin} />
          </Box>
          <Box py={4} display="flex" alignItems="center">
            <Typography
              variant="caption"
              align="center"
              style={{ color: `${mainColors.lightBlue}` }}>
              Code auf Stempelkarte scannen zum Anmelden.
            </Typography>
          </Box>
          <Button onClick={() => setScanOn(!scanOn)}>
            <Typography style={{ color: `${mainColors.lightBlue}` }}>
              MANUELLE EINGABE
            </Typography>
          </Button>
        </Grid>
      )
    }

    function ManualOptionSection() {
      return (
        <Grid
          className={`${classes.inputSection}`}
          container
          justify="flex-start"
          direction="column"
          alignItems="center">
          <Box pt={2} pb={3}>
            <Grid
              className={`${styles.pinBoxContainer}`}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}>
              {Object.entries(pin).map(
                (key: any, pinIndex: number, pinEntries) => (
                  <Grid item xs={3} key={key[0]}>
                    <PinBox
                      id={`pin-box-${key[0]}`}
                      inputRef={pinInputs[pinIndex]}
                      variant="outlined"
                      className={`${classes.pinBox} ${styles.pinBox}`}
                      inputProps={{ min: 0, style: { textAlign: 'center' } }}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === DELETE_KEY_CODE) {
                          handlePinChange('', key[0])

                          // auto focus previous field
                          if (pinIndex > 0) {
                            pinInputs[pinIndex - 1].current.focus()
                          }
                        }
                      }}
                      onChange={(e: any) => {
                        const inputLength = e.target.value.length
                        const pinLength = pinEntries.length

                        if (inputLength > 0) {
                          for (
                            let charIndex = 0;
                            charIndex < Math.min(inputLength, pinLength);
                            charIndex++
                          ) {
                            if (charIndex + pinIndex < pinLength) {
                              console.log(
                                pinIndex,
                                parseInt(
                                  pinEntries[charIndex + pinIndex][0],
                                  10
                                ),
                                e.target.value.substr(charIndex, 1)
                              )
                              handlePinChange(
                                e.target.value.substr(charIndex, 1),
                                parseInt(
                                  pinEntries[charIndex + pinIndex][0],
                                  10
                                )
                              )
                            }
                          }

                          // auto focus next field
                          if (pinIndex < pinLength - 1) {
                            pinInputs[
                              Math.min(pinIndex + inputLength, pinLength - 1)
                            ].current.focus()
                          }
                        }
                      }}
                      value={pin[key[0]]}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>
          <Box py={1}>
            <Button
              className={`${classes.button}`}
              ref={confirmButton}
              variant="contained"
              onClick={() => handleLogin()}>
              <Typography>ANMELDEN</Typography>
            </Button>
          </Box>
          <Box py={1}>
            <Button
              className={`${classes.button}`}
              style={{ color: `${mainColors.white}` }}
              onClick={() => {
                setScanOn(!scanOn)
                dispatch({ type: 'reset' })
              }}
              variant="text">
              <Typography>ZURÜCK</Typography>
            </Button>
          </Box>
        </Grid>
      )
    }

    function handlePinChange(value: string, key: number) {
      if (value.length <= 1) {
        dispatch({ type: key.toString(), value: value })
      } else {
        dispatch({ type: key.toString(), value: '' })
      }
    }

    function handleLogin() {
      const loginPin = id
      const manual = Object.values(pin).join('')
      if (isInputFilled()) {
        if (loginPin) authenticationStore.login(loginPin, true)
        else authenticationStore.login(manual)
      } else
        enqueueSnackbar('Personalnummer unvollständig.', { variant: 'error' })
    }

    function isInputFilled() {
      return id !== '' || !isPinEmpty()
    }

    function isPinEmpty() {
      return Object.values(pin).length !== 4 || Object.values(pin).includes('')
    }
  })
)

export default Login
