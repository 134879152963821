import React from 'react'
import {
  LinearProgress,
  LinearProgressProps,
  withStyles,
} from '@material-ui/core'
import { mainColors } from '../../styles/createMyTheme'
import { makeStyles } from '@material-ui/styles'

interface ProgressBar extends LinearProgressProps {
  classes: any
  value: number
  rejected?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '.65em',
    borderRadius: '6px',
  },
}))
const ProgressBar: React.FunctionComponent<any> = (props: ProgressBar) => {
  const { classes, value, rejected } = props
  const styles = useStyles()
  return (
    <LinearProgress
      {...props}
      value={value}
      className={styles.root}
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: !rejected
          ? classes.barColorPrimary
          : classes.barColorError,
      }}
    />
  )
}
const styles = (props) => ({
  colorPrimary: {
    backgroundColor: mainColors.ultraLightGrey,
  },
  barColorPrimary: {
    background: mainColors.progressGrad,
    borderRadius: '6px',
  },
  barColorError: {
    background: mainColors.rejectedGrad,
    borderRadius: '6px',
  },
})

export default withStyles(styles)(ProgressBar)
