import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import AuthenticationStore from '../../stores/AuthenticationStore'
import { Stores } from '../../stores'
import { Button, Dialog, Grid, Typography, withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { mainColors } from '../../styles/createMyTheme'
import OperationStore from 'stores/OperationStore'

interface LogoutDialogProps {
  authenticationStore?: AuthenticationStore
  operationStore?: OperationStore
  classes: any
}

const dialogStyles = (props) => ({
  dialogPaper: {
    minHeight: '60vh',
    maxHeight: '85vh',
    width: '85vw',
    background: 'transparent',
    zIndex: 3000,
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    color: mainColors.white,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },
  counter: {
    color: mainColors.error,
    fontSize: '10em',
    fontWeight: 'bolder',
  },
  counterUnits: {
    color: mainColors.white,
    fontSize: '2.5em',
    fontWeight: 'bold',
  },
  button: {
    color: mainColors.white,
    background: mainColors.cancelGrad,
    colors: mainColors.white,
  },
  buttonLetters: {
    colors: mainColors.white,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },
}))

const LogoutDialog: React.FunctionComponent<any> = inject(
  Stores.authenticationStore,
  Stores.operationStore
)(
  observer((props: LogoutDialogProps) => {
    const { authenticationStore, operationStore, classes } = props
    const [loadingDots, setLoadingDots] = useState('...')
    const [counter, setCounter] = useState(5)
    const customClasses = useStyles()
    useEffect(() => {
      if (authenticationStore.isLoggingOut) {
        const timer = setTimeout(() => {
          changeLoadingDots()
          changeCounter()
          if (counter === 0) {
            close()
            operationStore.closeDetailsView()
            authenticationStore.cleanLoginData()
          }
          if (!authenticationStore.isLoggingOut) clearTimeout(timer)
        }, 1000)
        return () => clearTimeout(timer)
      }
    }, [counter, loadingDots, authenticationStore.isLoggingOut])
    return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={close}
        open={authenticationStore.isLoggingOut}
        maxWidth={'xl'}>
        <Grid
          container
          className={customClasses.root}
          justify={'center'}
          alignItems={'center'}
          spacing={2}
          direction={'column'}>
          <Grid item>
            <Typography className={customClasses.title}>
              {' '}
              Auto Logout in{loadingDots}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              justify={'center'}
              alignItems={'flex-end'}
              direction={'row'}
              spacing={2}>
              <Grid item>
                <Typography className={customClasses.counter}>
                  {counter}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={customClasses.counterUnits}>
                  SEK
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              className={customClasses.button}
              onClick={() => {
                authenticationStore.cancelAutoLogoff()
              }}>
              <Typography className={customClasses.buttonLetters}>
                ANGEMELDET BLEIBEN
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    )
    function close() {
      authenticationStore.cancelAutoLogoff()
    }

    function changeLoadingDots() {
      if (loadingDots === '...') setLoadingDots('.')
      else setLoadingDots(loadingDots + '.')
    }

    function changeCounter() {
      if (counter > 0) setCounter(counter - 1)
    }
  })
)

export default withStyles(dialogStyles)(LogoutDialog)
