import {
  AppBar,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import HuebnerLogo from '../../assets/icons/hubner.svg'
import styles from './HeaderBar.module.scss'
import { useTranslation } from 'react-i18next'
import { AccountCircle, Clear } from '@material-ui/icons'
import { mainColors } from '../../styles/createMyTheme'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../stores'
import OperationStore from '../../stores/OperationStore'
import AuthenticationStore from '../../stores/AuthenticationStore'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  headerBar: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '6vh',
    height: '6vh',
  },
  title: {
    flexGrow: 8,
    textAlign: 'center',
  },
  icon: {
    flexGrow: 1,
    padding: 0,
    color: mainColors.white,
  },
  imageIcon: {
    fontSize: 30,
  },
  numberContainer: {
    width: '42%',
  },
  numberLabel: {
    marginLeft: '5px',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    marginLeft: '.25em',
    marginRight: '.25em',
  },
  typeContainer: {
    flexGrow: 1,
  },
  detailsToolbar: {
    marginBottom: '1em',
  },
  backDrop: {
    background: 'transparent',
    backgroundColor: 'transparent',
  },
}))

interface HeaderProps {
  operationStore?: OperationStore
  authenticationStore?: AuthenticationStore
}

const HeaderBar: React.FunctionComponent<any> = inject(
  Stores.operationStore,
  Stores.authenticationStore
)(
  observer((props: HeaderProps) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { operationStore, authenticationStore } = props
    const [profileDialogOpen, setProfileDialog] = useState<boolean>(false)
    const profileRef = useRef(null)

    return (
      <div className={`${classes.root}`}>
        <Popover
          ref={profileRef}
          open={profileDialogOpen}
          onBackdropClick={() => setProfileDialog(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}>
          <Grid
            style={{ width: '180px', height: '140px' }}
            container
            justify={'center'}
            alignItems={'center'}
            direction={'column'}>
            <Grid item>
              <Typography variant={'h5'}>Alex Schmitt</Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: mainColors.mediumGrey }}>
                Werk DE, Frankfurt
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant={'contained'}
                onClick={() => {
                  setProfileDialog(false)
                  authenticationStore.cleanLoginData()
                }}>
                <Typography>ABMELDEN</Typography>
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <AppBar
          className={`${classes.headerBar} ${styles[getBannerColor()]}`}
          position={'static'}>
          <Toolbar style={{ display: 'flex' }} variant={'dense'}>
            {operationStore?.isDetailsView ? (
              <IconButton
                className={classes.icon}
                children={<Clear className={classes.imageIcon} />}
                onClick={() => operationStore.closeDetailsView()}
              />
            ) : (
              <img
                className={classes.image}
                src={HuebnerLogo}
                alt={'huebner-logo'}
              />
            )}
            <Typography variant={'h5'} className={classes.title}>
              Alex Schmitt
            </Typography>
            <IconButton
              className={classes.icon}
              onClick={() => {
                profileRef.current && profileRef.current.focus()
                setProfileDialog(true)
              }}
              children={<AccountCircle className={classes.imageIcon} />}
            />
          </Toolbar>
          {operationStore?.isDetailsView && (
            <Toolbar className={classes.detailsToolbar}>
              <Grid
                container
                justify={'center'}
                alignItems={'flex-start'}
                direction={'column'}>
                <Grid
                  container
                  justify={'space-between'}
                  alignItems={'center'}
                  direction={'row'}>
                  <Grid
                    className={classes.numberContainer}
                    container
                    justify={'flex-start'}
                    alignItems={'center'}
                    direction={'row'}>
                    <Grid item>
                      <Typography variant={'h6'}>Vorgang</Typography>
                    </Grid>
                    <Grid item>
                      <div
                        style={{
                          backgroundColor: getLabelColor(),
                        }}
                        className={classes.numberLabel}>
                        <Typography className={classes.number} variant={'h6'}>
                          {operationStore.currentOperation.number}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {t(operationStore.currentOperationType).toUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography>
                    {operationStore.currentOperation.order.projectName}
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          )}
        </AppBar>
      </div>
    )

    function getBannerColor() {
      if (operationStore.isCurrentOperationOpen()) return 'HeaderBarOpen'
      else return 'HeaderBarInProgress'
    }
    function getLabelColor() {
      if (operationStore.isCurrentOperationOpen()) return mainColors.blue
      else return mainColors.green
    }
  })
)
export default HeaderBar
