import React from 'react'
import { IOperation } from '../../interfaces/IOperation'
import { Divider, Grid, List, ListItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { mainColors } from '../../styles/createMyTheme'
import mockData from '../../Utils/MockData/data'
import barcode from '../../assets/icons/barcode.svg'
import check from '../../assets/icons/check.svg'
import file from '../../assets/icons/file-document.svg'
import marker from '../../assets/icons/map-marker.svg'
import folder from '../../assets/icons/folder-outline.svg'
import closePackage from '../../assets/icons/package-variant-closed.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  list: {
    overflowY: 'auto',
    width: '100%',
    maxHeight: '60vh',
  },
  listItem: {
    marginTop: '.5em',
    padding: '.5em',
  },
  numberContainer: {
    borderRadius: '3px',
    display: 'flex',
    backgroundColor: mainColors.ultraLightGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    marginLeft: '.25em',
    marginRight: '.25em',
  },
  itemStatus: {
    fontSize: '15px',
  },

  image: {
    width: '30px',
    height: '50px',
    color: mainColors.lightGrey,
  },

  location: {
    fontSize: '1em',
    color: mainColors.mediumGrey,
  },
}))

interface processDetailsProps {
  operation: IOperation
}

const mockOperation = mockData.operations[1]
const DetailsTab: React.FunctionComponent<any> = (
  props: processDetailsProps
) => {
  const { operation = mockOperation } = props
  const classes = useStyles()
  return (
    <List className={classes.list}>
      {getListItem(operation, 'operationCode')}
      <Divider />
      {getListItem(operation, 'plannedAmount')}
      <Divider />
      {getListItem(operation, 'order')}
      <Divider />
      {getListItem(operation, 'location')}
      <Divider />
      {getListItem(operation, 'project')}
      <Divider />
      {getListItem(operation, 'material')}
    </List>
  )

  function getListItem(item: IOperation, detailItem: string) {
    let label,
      content = ''
    let imageSrc = ''
    let title = ''
    switch (detailItem) {
      case 'operationCode':
        content = item.confirmationNumber
        imageSrc = barcode
        title = 'Rückmeldecode'
        break
      case 'plannedAmount':
        content = `${item.plannedAmount} ${item.unit}`
        imageSrc = check
        title = 'Geplante Menge'
        break
      case 'order':
        content = item.order.number
        imageSrc = file
        title = 'Auftrag'
        break
      case 'location':
        label = item.workcenterNumber
        content = item.workcenterName
        imageSrc = marker
        title = 'Arbeitsplatz'
        break
      case 'project':
        label = item.order.projectNumber
        content = item.order.projectName
        imageSrc = folder
        title = 'Serie'
        break
      case 'material':
        label = item.order.materialNumber
        content = item.order.materialName
        imageSrc = closePackage
        title = 'Material'
    }
    const reducedContent =
      content.length > 27 ? content.substring(0, 26) + '...' : content
    return (
      <ListItem className={classes.listItem}>
        <Grid
          container
          justify={'space-between'}
          alignItems={'center'}
          direction={'row'}>
          <Grid item>
            <Grid
              container
              justify={'flex-start'}
              alignItems={'center'}
              direction={'row'}
              spacing={2}>
              <Grid item>
                <div>
                  <img
                    className={classes.image}
                    src={imageSrc}
                    alt={`details-img-${title}`}
                  />
                </div>
              </Grid>
              <Grid item>
                <Grid
                  container
                  alignItems={'flex-start'}
                  justify={'flex-start'}
                  direction={'column'}
                  spacing={1}>
                  {label && (
                    <Grid item>
                      <div className={classes.numberContainer}>
                        <Typography
                          className={classes.number}
                          variant={'caption'}>
                          {label}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography className={classes.itemStatus}>
                      {reducedContent}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justify={'center'} alignItems={'center'}>
              <Typography className={classes.location} variant={'caption'}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    )
  }
}

export default DetailsTab
