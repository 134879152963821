import React, { useEffect } from 'react'
import { Router, Switch } from 'react-router-dom'
import Login from 'pages/Login/Login'
import Home from 'pages/Home'
import PrivateRoute from 'components/PrivateRoute'
import { inject, observer } from 'mobx-react'
import RootStore from 'stores/RootStore'
import RouterStore, { history } from 'stores/RouterStore'
import LoginRoute from './components/LoginRoute'
import ERoutes from './interfaces/IGlobal'
import { Stores } from './stores'
import Loader from './components/Loader/Loader'
import SnackbarStore from './stores/SnackbarStore'
import { useSnackbar, VariantType } from 'notistack'
import ReactSound from 'react-sound'
interface RouterProps {
  rootStore?: RootStore
  routerStore?: RouterStore
  snackbarStore?: SnackbarStore
}

const successSound = './assets/sounds/beep.mp3'
const errorSound = './assets/sounds/error.mp3'

const CustomRouter: React.FC<RouterProps> = inject(
  Stores.rootStore,
  Stores.routerStore,
  Stores.snackbarStore
)(
  observer(({ rootStore, routerStore, snackbarStore }) => {
    const appIsLoading = () => {
      const { loginLoading } = rootStore!
      return loginLoading
    }
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
      if (snackbarStore.text && snackbarStore.variant) {
        enqueueSnackbar(snackbarStore.text, {
          variant: snackbarStore.variant as VariantType,
        })
      }
    }, [snackbarStore.text, snackbarStore.variant])

    return (
      <Router history={history}>
        <Loader open={appIsLoading()} />
        <ReactSound
          url={rootStore.scanSound === 'success' ? successSound : errorSound}
          playStatus={rootStore.scanState}
          onFinishedPlaying={() => {
            rootStore.stopPlaying()
          }}
        />
        <div className="header-offset">
          <Switch>
            <PrivateRoute exact path={'/'} component={Login} />
            <LoginRoute exact path={ERoutes.LOGIN} component={Login} />
            <PrivateRoute exact path={ERoutes.HOME} component={Home} />
          </Switch>
        </div>
      </Router>
    )
  })
)

export default CustomRouter
