import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import { mainColors } from '../../../styles/createMyTheme'
import { Clear, List as ListIcon } from '@material-ui/icons'
import OperationStore from '../../../stores/OperationStore'
import { makeStyles } from '@material-ui/core/styles'
import { Stores } from '../../../stores'
import { OperationStates } from '../../../interfaces/IOperation'
import AuthenticationStore from '../../../stores/AuthenticationStore'

interface PauseDialogProps {
  operationStore?: OperationStore
  authenticationStore?: AuthenticationStore
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.60em',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  button: {
    marginBottom: '20px',
    background: mainColors.cancelGrad,
    width: '90%',
    alignSelf: 'center',
    color: 'white',
  },
  caption: {
    color: mainColors.mediumGrey,
  },
}))

const stopReasons = [
  'Mitarbeiterpause',
  'Fehlteile',
  'Maschinenfehler',
  'Defektes Werkzeug',
  'Sonstiges',
]

const PauseDialog: React.FunctionComponent<any> = inject(
  Stores.operationStore,
  Stores.authenticationStore
)(
  observer((props: PauseDialogProps) => {
    const { operationStore, authenticationStore } = props

    const [pauseReason, setPauseReason] = useState(stopReasons[0])
    const classes = useStyles()
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            operationStore.changeOperationType(
              OperationStates.partlyConfirmed,
              true
            )
            operationStore.closeDialogs()
          }}
          className={classes.button}
          variant={'contained'}>
          <Typography variant={'h6'}>UNTERBRECHEN</Typography>
        </Button>
        <Paper className={classes.root}>
          <Grid
            container
            justify={'center'}
            alignItems={'center'}
            direction={'column'}>
            <Grid
              container
              alignItems={'center'}
              justify={'space-between'}
              direction={'row'}>
              <Typography variant={'h5'}>UNTERBRECHUNG</Typography>
              <IconButton onClick={() => operationStore.closeDialogs()}>
                <Clear />
              </IconButton>
            </Grid>

            <Grid item style={{ width: '100%' }}>
              <Grid
                container
                alignItems={'center'}
                justify={'space-between'}
                direction={'row'}>
                <Grid item>
                  <ListIcon
                    fontSize={'large'}
                    style={{ color: mainColors.lightGrey, marginTop: '20px' }}
                  />
                </Grid>
                <Grid item style={{ width: '80%' }}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id="scrap-reason-label">
                      Unterbrechungsgrund
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pauseReason}
                      onChange={handleChangeReason}
                      color={'primary'}>
                      {stopReasons.map((item, index) => (
                        <MenuItem key={'reason' + index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          alignItems={'center'}
          justify={'center'}
          direction={'row'}
          style={{ backgroundColor: mainColors.ultraLightGrey }}>
          <Grid item>
            <Switch
              checked={authenticationStore.stayLoggedIn}
              onChange={(e: any) =>
                authenticationStore.handleLoginSelectorToggle()
              }
              color={'primary'}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.caption} variant={'caption'}>
              Angemeldet bleiben
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )

    function handleChangeReason(event) {
      setPauseReason(event.target.value)
    }
  })
)

export default PauseDialog
