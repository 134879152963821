export enum OperationStates {
  open = 'OPEN',
  inProgress = 'IN_PROGRESS',
  inRework = 'IN_REWORK',
  inSetup = 'IN_SETUP',
  partlyConfirmed = 'PARTLY_CONFIRMED',
  confirmed = 'CONFIRMED',
}

export interface IComponent {
  pos: string
  number: string
  name: string
  document: string
  amountPerBasis: number
  totalAmount: number
  unit: string
}
export interface IOrder {
  number: string
  materialNumber: string
  materialName: string
  projectNumber: string
  projectName: string
}
export interface IConfirmationComponent {
  number: string
  yield: number
  scrap: number
  scrapReason: string | null
}
export interface IConfirmation {
  user: string
  yield: number
  scrap: number
  scrapReason: string | null
  rework: number
  reasonOfConfirmation: string | null
  finalConfirmation: boolean
  start: string
  end: string
  components: IConfirmationComponent[]
}

export interface IOperation {
  __state: OperationStates | string
  number: string
  confirmationNumber: string
  workcenterNumber: string
  workcenterName: string
  plannedAmount: number
  plannedBasis: number
  estimatedScrap: number
  unit: string
  components: IComponent[]
  order: IOrder
  lastUpdate: number
  confirmations: IConfirmation[]
}

export const newConfirmation: IConfirmation = {
  user: '1371',
  yield: 0,
  scrap: 0,
  scrapReason: '',
  rework: 0,
  reasonOfConfirmation: '',
  finalConfirmation: false,
  start: '',
  end: '',
  components: [],
}
