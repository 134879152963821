import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ERoutes from '../interfaces/IGlobal'
import { Stores } from '../stores'

const PrivateRoute = inject(Stores.authenticationStore)(
  observer(
    ({
      component: Component,
      authenticationStore,
      loginRoute,
      ...rest
    }: any) => {
      return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route
          {...rest}
          render={(props) =>
            authenticationStore.loggedIn ? (
              <Component {...props} {...rest} />
            ) : (
              <Redirect to={ERoutes.LOGIN} />
            )
          }
        />
      )
    }
  )
)

export default PrivateRoute
