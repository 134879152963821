import RootStore from './RootStore'
import { createBrowserHistory } from 'history'
import { action, observable } from 'mobx'

export const history = createBrowserHistory()

class SnackbarStore {
  rootStore: RootStore
  @observable isSnackbarOpen: boolean = false
  @observable variant = 'success'
  @observable text = ''

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action openSnackbar(text: string, variant: string) {
    this.text = text
    this.variant = variant
    this.isSnackbarOpen = true
  }
  @action closeSnackbar() {
    this.variant = 'success'
    this.isSnackbarOpen = false
    this.text = ''
  }
}

export default SnackbarStore
