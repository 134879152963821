import createMuiTheme, { Theme } from '@material-ui/core/styles/createMuiTheme'
import { withStyles, createStyles } from '@material-ui/styles'
import { TableCell, Tooltip, Button, Badge } from '@material-ui/core'
import colors from './constants.module.scss'
import { makeStyles } from '@material-ui/core/styles'
import { OperationStates } from '../interfaces/IOperation'
export const mainColors = colors
const myTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.blue, // Huebner Primary
      contrastText: colors.white,
    },
    secondary: {
      main: colors.orange, // Huebner Orange
      contrastText: colors.white,
    },
    inProgress: {
      main: colors.green,
      contrastText: colors.white,
    },
    error: {
      main: '#a8353a', // Huebner Red
      contrastText: colors.white,
    },
    info: {
      main: '#8a8a8a', // Huebner Gray
      contrastText: colors.white,
    },
    infoButton: {
      main: colors.ultraLightGrey,
      contrastText: colors.black,
    },
  },
} as any)

export const getThemeByType = (type?: string) => {
  if (type === OperationStates.open) return 'primary'
  else return 'inProgress'
}

export const containerStyle = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '1.5em',
    backgroundColor: colors.white,
    width: '100%',
  },
}))

export const HeaderTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#8a8a8a',
      color: theme.palette.common.white,
      borderBottom: '0px',
    },
  })
)(TableCell)

export const DefaultTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(16),
    color: '#fffff',
  },
}))(Tooltip)

export const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#e30935',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
}))(Button)

export const AllBelowButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 10,
    height: 30,
    margin: '0px 5px 0px 5px',
  },
}))(Button)

export const ConfirmButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#ffffff',
    borderColor: '#e30935',
    color: '#e30935',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
}))(Button)

export const GreyBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#8a8a8a',
      color: '#fff',
    },
  })
)(Badge)

export default myTheme
