import React from 'react'
import { Divider, Grid, List, ListItem, Typography } from '@material-ui/core'
import { IConfirmation, IOperation } from '../../interfaces/IOperation'
import { makeStyles } from '@material-ui/styles'
import { mainColors } from '../../styles/createMyTheme'
import mockData from '../../Utils/MockData/data'
import { Flag } from '@material-ui/icons'
import ProgressBar from './ProgressBar'
import { useTranslation } from 'react-i18next'
import barcode from '../../assets/icons/barcode.svg'
import OperationStore from '../../stores/OperationStore'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../stores'

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: mainColors.white,
  },
  numberContainer: {
    borderRadius: '3px',
    display: 'flex',
    backgroundColor: mainColors.ultraLightGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    paddingLeft: '.25em',
    paddingRight: '.25em',
  },
  caption: {
    color: mainColors.mediumGrey,
  },

  circle: {
    width: '1.0em',
    height: '1.0em',
    borderRadius: '50%',
  },

  totalCircle: {
    background: mainColors.lightGrey,
  },

  balanceCircle: {
    background: mainColors.progressGrad,
  },

  rejectedCircle: {
    background: mainColors.rejectedGrad,
  },
}))

interface feedbackProps {
  confirmations: IConfirmation[]
  operationStore?: OperationStore
  operation: IOperation
}

const mockConfirmations: IConfirmation[] = mockData.operations[1].confirmations
const mockOperation: IOperation = mockData.operations[1]
const FeedbackTab: React.FunctionComponent<any> = inject(Stores.operationStore)(
  observer((props: feedbackProps) => {
    const {
      confirmations = mockConfirmations,
      operation = mockOperation,
      operationStore,
    } = props
    const { t } = useTranslation()
    const classes = useStyles()
    return (
      <div style={{ textAlign: 'center' }}>
        <Grid
          style={{ marginTop: '5px', marginBottom: '5px' }}
          container
          alignItems={'center'}
          justify={'center'}
          direction={'column'}
          spacing={2}>
          <Grid item>
            <Grid
              container
              alignItems={'center'}
              justify={'center'}
              spacing={1}
              direction={'row'}>
              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}>
                  <Grid item>
                    <div
                      className={`${classes.circle} ${classes.totalCircle}`}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.caption} variant={'caption'}>
                      Geplante Menge (
                      {operationStore.currentOperation.plannedAmount})
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}>
                  <Grid item>
                    <div
                      className={`${classes.circle} ${classes.balanceCircle}`}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.caption} variant={'caption'}>
                      Gutmenge ({operationStore.getSummedConfirmationYield()})
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}>
                  <Grid item>
                    <div
                      className={`${classes.circle} ${classes.rejectedCircle}`}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.caption} variant={'caption'}>
                      Ausschuss ({operationStore.getSummedConfirmationScrap()})
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '95%' }}>
            <ProgressBar
              variant={'determinate'}
              value={getTotalConfirmationYieldRatio()}
            />
          </Grid>
          {operationStore.getSummedConfirmationScrap() > 0 && (
            <Grid item style={{ width: '95%' }}>
              <ProgressBar
                variant={'determinate'}
                value={getTotalConfirmationScrapRatio()}
                rejected={true}
              />
            </Grid>
          )}
        </Grid>
        {confirmations.length > 0 ? (
          <List>
            {confirmations.map((confirmation: IConfirmation, index: number) =>
              getConfirmationItem(confirmation, index, confirmations.length)
            )}
          </List>
        ) : (
          <Typography variant={'h5'}>Keine Rückmeldung</Typography>
        )}
      </div>
    )

    function getConfirmationItem(
      item: IConfirmation,
      index: number,
      listLength: number
    ) {
      return (
        <React.Fragment key={index}>
          {index === 0 && <Divider />}
          <ListItem>
            <Grid
              container
              direction={'column'}
              alignItems={'center'}
              spacing={1}>
              <Grid item style={{ width: '100%', flexDirection: 'row' }}>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  justify={'space-between'}>
                  <Grid item>
                    <Grid
                      container
                      alignItems={'center'}
                      justify={'flex-start'}
                      direction={'row'}>
                      <Grid item>
                        {item.finalConfirmation ? (
                          <Flag style={{ color: mainColors.blue }} />
                        ) : (
                          <img
                            style={{ height: '30px' }}
                            src={barcode}
                            alt={'scan-icon'}
                          />
                        )}
                      </Grid>
                      <Grid item>
                        <Grid
                          style={{ paddingLeft: '1em' }}
                          container
                          direction={'column'}
                          alignItems={'flex-start'}
                          spacing={1}>
                          <Grid item>
                            <div className={classes.numberContainer}>
                              <Typography
                                className={classes.number}
                                variant={'caption'}>
                                {item.user} / Alex Schmitt
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item>
                            <Typography>{item.scrapReason}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography className={classes.caption} variant={'caption'}>
                      {item.start + 'h'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item style={{ width: '100%' }}>
                <Grid
                  container
                  alignItems={'flex-start'}
                  justify={'flex-start'}
                  direction={'column'}>
                  <Grid style={{ marginLeft: '2em' }} item>
                    <Grid
                      container
                      alignItems={'center'}
                      justify={'center'}
                      spacing={2}
                      direction={'row'}>
                      <Grid item>
                        <Grid
                          container
                          direction={'row'}
                          alignItems={'center'}
                          spacing={1}>
                          <Grid item>
                            <div
                              className={`${classes.circle} ${classes.balanceCircle}`}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.caption}
                              variant={'caption'}>
                              Gutmenge ({item.yield})
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction={'row'}
                          alignItems={'center'}
                          spacing={1}>
                          <Grid item>
                            <div
                              className={`${classes.circle} ${classes.rejectedCircle}`}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.caption}
                              variant={'caption'}>
                              Ausschuss ({item.scrap})
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <ProgressBar
                  variant={'determinate'}
                  value={getConfirmationProgressRatio(item)}
                />
              </Grid>
              {item.scrap > 0 && (
                <Grid item style={{ width: '100%' }}>
                  <ProgressBar
                    variant={'determinate'}
                    value={getConfirmationRejectedRatio(item)}
                    rejected={true}
                  />
                </Grid>
              )}
            </Grid>
          </ListItem>
          {index < listLength - 1 && <Divider />}
        </React.Fragment>
      )
    }

    function getTotalConfirmationYieldRatio() {
      const previousYield = operationStore.getSummedConfirmationYield()
      const number =
        previousYield >= operationStore.currentOperation.plannedAmount
          ? 100
          : previousYield
      return number === 100
        ? number
        : (number / operationStore.currentOperation.plannedAmount) * 100
    }
    function getTotalConfirmationScrapRatio() {
      const previousYield = operationStore.getSummedConfirmationScrap()
      const number =
        previousYield >= operationStore.currentOperation.plannedAmount
          ? 100
          : previousYield
      return number === 100
        ? number
        : (number / operationStore.currentOperation.plannedAmount) * 100
    }
    function getConfirmationProgressRatio(confirmation: IConfirmation) {
      const number =
        confirmation.yield >= operation.plannedAmount ? 100 : confirmation.yield
      return number === 100 ? number : (number / operation.plannedAmount) * 100
    }
    function getConfirmationRejectedRatio(confirmation: IConfirmation) {
      const number =
        confirmation.scrap >= operation.plannedAmount ? 100 : confirmation.scrap
      return number === 100 ? number : (number / operation.plannedAmount) * 100
    }
  })
)
export default FeedbackTab
