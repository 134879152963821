import React from 'react'
import { Provider } from 'mobx-react'
import stores from './stores'
import './i18n'
// import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import myTheme from './styles/createMyTheme'
import CustomizedSnackbars from './components/Snackbar/Snackbar'
import CustomRouter from './Router'

function App() {
  return (
    <Provider {...stores}>
      <CssBaseline />
      <ThemeProvider theme={myTheme}>
        <CustomizedSnackbars app={CustomRouter} />
      </ThemeProvider>
    </Provider>
  )
}

export default App
