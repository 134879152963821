import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en.json'
import de from './locales/de.json'

const resources = {
  en: { ...en },
  de: { ...de },
}
const detectorOptions = {
  order: [
    'localStorage',
    'querystring',
    'cookie',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    detection: detectorOptions,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
