import React, { useEffect, useReducer, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../stores'
import { Grid, Typography } from '@material-ui/core'
import scanIcon from '../../assets/icons/scan-icon-grey-large.svg'
import { makeStyles } from '@material-ui/core/styles'
import { mainColors } from '../../styles/createMyTheme'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import OperationStore from '../../stores/OperationStore'
import styles from './Scanning.module.scss'

interface ICode {
  [key: number]: any
}

const DELETE_KEY_CODE = 8
const $intervals = {
  checkFocus: null,
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15vh',
  },
  caption: {
    color: mainColors.mediumGrey,
    fontWeight: 'bold',
  },
  codeBox: {
    backgroundColor: 'transparent',
    margin: '6px',
  },
  button: {
    width: '40%',
    marginTop: '3.5vh',
  },
  buttonGroup: {
    boxShadow: 'none !important',
    background: '#e0e0e0 !important',
    padding: '4px',
  },
  buttonOn: {
    background: 'white !important',
    border: 'none !important',
    borderRadius: '4px !important',
  },
  buttonOff: {
    border: 'none !important',
    borderRadius: '4px !important',
  },
}))

const PinBox = withStyles({
  root: {
    backgroundColor: 'transparent',
    '& label.Mui-focused': {
      color: mainColors.lightGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: mainColors.lightGrey,
      },
      '&:hover fieldset': {
        borderColor: mainColors.blue,
      },
      '&.Mui-focused fieldset': {
        borderColor: mainColors.blue,
      },
    },
  },
})(TextField)

interface ScanningProps {
  operationStore?: OperationStore
}
const Scanning: React.FunctionComponent<any> = inject(Stores.operationStore)(
  observer((props: ScanningProps) => {
    const { operationStore } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const scanRef = useRef(null)
    const confirmButton = useRef(null)
    const codeRefs = [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ]
    const [scanOn, setScanOn] = useState<boolean>(true)
    const [scannedCode, setScannedCode] = useState<string>('')
    const initialCode: ICode = {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      7: '',
    }
    const codeReducer = (state: ICode, action: any) => {
      switch (action.type) {
        case 'reset': {
          return initialCode
        }
        default: {
          return Object.entries(initialCode).filter(
            (key) => key[0] === action.type
          ).length > 0
            ? { ...state, [`${action.type}`]: action.value }
            : state
        }
      }
    }
    const [manualCode, dispatch] = useReducer(codeReducer, initialCode)
    const inputFilled = isInputFilled()

    useEffect(() => {
      dispatch('reset')
      setScannedCode('')
      if (scanOn && scanRef.current) {
        clearInterval($intervals.checkFocus)
        $intervals.checkFocus = setInterval(() => {
          if (scanRef.current) {
            if (scanRef.current !== document.activeElement) {
              scanRef.current.focus()
            }
          }
        }, 100)
      } else if (codeRefs[0].current) {
        clearInterval($intervals.checkFocus)
        codeRefs[0].current.focus()
      }
      // eslint-disable-next-line
    }, [scanOn])

    useEffect(() => {
      if (scannedCode)
        operationStore.searchScannedConfirmation(scannedCode, true)
      if (operationStore.scanningValid === false) setScannedCode('')
      // eslint-disable-next-line
    }, [scannedCode, operationStore.scanningValid])

    useEffect(() => {
      if (inputFilled) {
        confirmButton.current.focus()
        handleConfirmCode()
      }
      // eslint-disable-next-line
    }, [inputFilled])

    return (
      <Grid
        className={classes.root}
        container
        justify={'center'}
        direction={'column'}
        alignItems={'center'}>
        <Grid item style={{ marginBottom: '5vh' }}>
          <Typography variant={'h5'}> Hallo Alex Schmitt </Typography>
        </Grid>

        {scanOn ? ScanSection() : ManualOptionSection()}
        <Grid item style={{ marginTop: '3.5vh' }}>
          <ButtonGroup
            variant={'contained'}
            color={'default' as any}
            className={classes.buttonGroup}>
            <Button
              className={scanOn ? classes.buttonOn : classes.buttonOff}
              onClick={() => setScanOn(true)}>
              Scannen
            </Button>
            <Button
              className={!scanOn ? classes.buttonOn : classes.buttonOff}
              onClick={() => setScanOn(false)}>
              Eingabe
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    )

    function ScanSection() {
      return (
        <React.Fragment>
          <Grid item>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
              }}>
              <Box position="relative" width="auto">
                <div className={`${styles.diode}`}>
                  <div className={`${styles.laser}`}></div>
                </div>
                <img src={scanIcon} alt={'scan icon'} />
              </Box>
              <input
                id={'scanning-code-input'}
                ref={scanRef}
                style={{ opacity: '0', height: '1px' }}
                value={scannedCode}
                onChange={(e) => setScannedCode(e.target.value)}
              />
            </div>
          </Grid>

          <Grid
            item
            container
            justify={'center'}
            style={{ marginTop: '2.5vh' }}>
            <Typography
              className={classes.caption}
              style={{ textAlign: 'center' }}
              variant={'caption'}>
              Scanne den Rückmeldungscode des Vorgangs zum FORTFAHREN.
            </Typography>
          </Grid>
        </React.Fragment>
      )
    }

    function ManualOptionSection() {
      return (
        <Grid
          style={{ width: '100%' }}
          container
          justify="center"
          direction="column"
          alignItems="center">
          <Box mx={2}>
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={0}>
              {Object.entries(manualCode).map(
                (key: any, codeIndex: number, codeEntries) => (
                  <Grid item key={key[0]} style={{ width: '14%' }}>
                    <PinBox
                      inputRef={codeRefs[codeIndex]}
                      id={`code-box-${key[0]}`}
                      variant="outlined"
                      autoFocus
                      className={`${classes.codeBox}`}
                      inputProps={{ min: 0, style: { textAlign: 'center' } }}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === DELETE_KEY_CODE) {
                          handleCodeChange('', key[0])

                          // auto focus previous field
                          if (codeIndex > 0) {
                            codeRefs[codeIndex - 1].current.focus()
                          }
                        }
                      }}
                      onChange={(e: any) => {
                        const inputLength = e.target.value.length
                        const pinLength = codeEntries.length

                        if (inputLength > 0) {
                          for (
                            let charIndex = 0;
                            charIndex < Math.min(inputLength, pinLength);
                            charIndex++
                          ) {
                            if (charIndex + codeIndex < pinLength) {
                              console.log(
                                codeIndex,
                                parseInt(
                                  codeEntries[charIndex + codeIndex][0],
                                  10
                                ),
                                e.target.value.substr(charIndex, 1)
                              )
                              handleCodeChange(
                                e.target.value.substr(charIndex, 1),
                                parseInt(
                                  codeEntries[charIndex + codeIndex][0],
                                  10
                                )
                              )
                            }
                          }

                          // auto focus next field
                          if (codeIndex < pinLength - 1) {
                            codeRefs[
                              Math.min(codeIndex + inputLength, pinLength - 1)
                            ].current.focus()
                          }
                        }
                      }}
                      value={manualCode[key[0]]}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>
          <Button
            className={`${classes.button}`}
            ref={confirmButton}
            color={'primary'}
            disabled={!isInputFilled()}
            onClick={handleConfirmCode}
            variant="text">
            <Typography style={{ fontWeight: 'bold' }}>BESTÄTIGEN</Typography>
          </Button>
        </Grid>
      )
    }

    function handleConfirmCode() {
      operationStore.searchScannedConfirmation(
        Object.values(manualCode).join('')
      )
    }

    function isInputFilled() {
      return Object.values(manualCode).filter((val) => val !== '').length === 7
    }

    function handleCodeChange(value: any, key: number) {
      if (value.length <= 1) {
        dispatch({ type: key.toString(), value: value })
      } else {
        dispatch({ type: key.toString(), value: '' })
      }
    }
  })
)

export default Scanning
