import React, { Component, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { mainColors } from '../../styles/createMyTheme'
import { inject, observer } from 'mobx-react'
import SnackbarStore from '../../stores/SnackbarStore'
import { Stores } from '../../stores'
import { SnackbarProvider } from 'notistack'
// import CustomRouter from '../../Router'
// import { Cancel, CheckCircle } from '@material-ui/icons'
// import { Button } from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   success: { backgroundColor: mainColors.black + '!important', color: 'green' },
//   error: {
//     backgroundColor: mainColors.black + '!important',
//     color: '#ff0000' + '!important',
//   },
//   warning: { backgroundColor: mainColors.black + '!important' },
//   info: { backgroundColor: mainColors.black + '!important' },
// }))

interface SnackbarProps {
  app: React.FC
  snackbarStore?: SnackbarStore
}

const CustomizedSnackbars: React.FunctionComponent<any> = inject(
  Stores.snackbarStore
)(
  observer((props: SnackbarProps) => {
    const { snackbarStore, app: App } = props
    // const classes = useStyles()
    const notistackRef = useRef(null)

    // const onClickDismiss = (key) => () => {
    //   notistackRef &&
    //     notistackRef.current &&
    //     notistackRef.current.closeSnackbar(key)
    // }

    return (
      <SnackbarProvider
        dense
        ref={notistackRef}
        // maxSnack={1}
        // action={(key) => (
        //   <Button
        //     variant={'text'}
        //     style={{ color: mainColors.green }}
        //     onClick={onClickDismiss(key)}>
        //     VERWERFEN
        //   </Button>
        // )}
        // classes={{
        //   variantSuccess: classes.success,
        //   variantError: classes.error,
        //   variantWarning: classes.warning,
        //   variantInfo: classes.info,
        // }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        // iconVariant={{
        //   success: (
        //     <CheckCircle
        //       style={{ color: mainColors.green, marginRight: '5px' }}
        //     />
        //   ),
        //   error: (
        //     <Cancel style={{ color: mainColors.green, marginRight: '5px' }} />
        //   ),
        // }}
        autoHideDuration={3000}
        onClose={() => snackbarStore.closeSnackbar()}
        onExit={() => snackbarStore.closeSnackbar()}>
        <App />
      </SnackbarProvider>
    )
  })
)

export default CustomizedSnackbars
