import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../stores'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core'
import { Add, Flag, Menu, Pause, PlayArrow, Settings } from '@material-ui/icons'
import scanIcon from '../../assets/icons/scan-icon-login-white.svg'
import { OperationStates } from '../../interfaces/IOperation'
import { mainColors } from '../../styles/createMyTheme'
import OperationStore from '../../stores/OperationStore'
import AuthenticationStore from '../../stores/AuthenticationStore'
import { useSnackbar } from 'notistack'
import SnackbarStore from '../../stores/SnackbarStore'

const openListSection = ['start', 'reStart', 'config', 'switch']
const workListSection = ['start', 'pause', 'switch']

interface actionsTabProps {
  operationStore?: OperationStore
  snackbarStore?: SnackbarStore
  authenticationStore?: AuthenticationStore
}

const $intervals = {
  checkFocus: null,
}

const ActionsTab: React.FunctionComponent<any> = inject(
  Stores.operationStore,
  Stores.snackbarStore,
  Stores.authenticationStore
)(
  observer((props: actionsTabProps) => {
    const { operationStore, authenticationStore, snackbarStore } = props
    const { t } = useTranslation()
    const [actionArr, setActionArr] = useState(openListSection)
    const [scanInput, setScanInput] = useState('')
    const scanRef = useRef(null)
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
      if (
        operationStore.isCurrentOperationOpen() &&
        operationStore.currentOperationType !== OperationStates.confirmed
      )
        setActionArr(openListSection)
      else if (
        operationStore.currentOperationType === OperationStates.confirmed
      )
        setActionArr([])
      else setActionArr(workListSection)
    }, [operationStore.currentOperationType])

    useEffect(() => {
      if (scanRef.current) {
        clearInterval($intervals.checkFocus)
        $intervals.checkFocus = setInterval(() => {
          if (scanRef.current) {
            if (scanRef.current !== document.activeElement) {
              scanRef.current.focus()
            }
          }
        }, 100)
      }
      if (scanInput === operationStore.currentOperation.confirmationNumber) {
        startJob()
        setScanInput('')
      } else if (
        scanInput !== operationStore.currentOperation.confirmationNumber &&
        scanInput.length > 0
      ) {
        snackbarStore.openSnackbar(
          'Rückmeldecode entspricht nicht aktuellen Vorgang',
          'error'
        )
        setScanInput('')
      }
    }, [scanRef.current, scanInput])

    return (
      <List style={{ padding: '0' }}>
        {actionArr.map((title: string, index: number, array: string[]) =>
          getOpenListItem(title, index, array)
        )}
      </List>
    )

    function getOpenListItem(
      title: string,
      index: number,
      actionArray: string[]
    ) {
      let section = <div />
      switch (title) {
        case 'start':
          section = (
            <Grid item>
              <Grid
                container
                justify={'space-evenly'}
                alignItems={'center'}
                spacing={2}
                direction={'row'}>
                <Grid item>
                  <img
                    style={{ height: '30px' }}
                    src={scanIcon}
                    alt={'scan-icon'}
                  />
                  <input
                    id={'scanning-code-input-action'}
                    ref={scanRef}
                    style={{ opacity: '0', height: '1px', width: '1px' }}
                    value={scanInput}
                    onChange={(e) => setScanInput(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justify={'center'}
                    alignItems={'center'}
                    spacing={1}
                    direction={'column'}
                    onClick={startJob}>
                    <Grid item>
                      <Typography variant={'h6'}>
                        {operationStore.isCurrentOperationOpen() ||
                        operationStore.currentOperationType ===
                          OperationStates.inSetup
                          ? 'Arbeit Starten'
                          : 'Rückmelden'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'caption'}>
                        {operationStore.isCurrentOperationOpen() ||
                        operationStore.currentOperationType ===
                          OperationStates.inSetup
                          ? 'Scanne um Arbeit zu starten.'
                          : 'Scanne um rückzumelden'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {operationStore.isCurrentOperationOpen() ||
                  operationStore.currentOperationType ===
                    OperationStates.inSetup ? (
                    <PlayArrow fontSize={'large'} />
                  ) : (
                    <Flag fontSize={'large'} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )
          break
        case 'pause':
          section = (
            <Grid item>
              <Grid
                container
                justify={'center'}
                alignItems={'center'}
                direction={'row'}
                spacing={2}
                onClick={() => {
                  operationStore.openPause()
                }}>
                <Grid item>
                  <Typography variant={'h6'}>Unterbrechen</Typography>
                </Grid>
                <Grid item>
                  <Pause fontSize={'large'} />
                </Grid>
              </Grid>
            </Grid>
          )
          break
        case 'reStart':
          section = (
            <Grid item>
              <Grid
                container
                justify={'center'}
                alignItems={'center'}
                direction={'row'}
                spacing={2}
                onClick={() => {
                  operationStore.changeOperationType(OperationStates.inRework)
                }}>
                <Grid item>
                  <Typography variant={'h6'}>Nacharbeit Starten</Typography>
                </Grid>
                <Grid item>
                  <Add fontSize={'large'} />
                </Grid>
              </Grid>
            </Grid>
          )
          break
        case 'config':
          section = (
            <Grid item>
              <Grid
                container
                justify={'center'}
                alignItems={'center'}
                direction={'row'}
                spacing={2}
                onClick={() => {
                  operationStore.changeOperationType(OperationStates.inSetup)
                }}>
                <Grid item>
                  <Typography variant={'h6'}>Rüsten Starten</Typography>
                </Grid>
                <Grid item>
                  <Settings fontSize={'large'} />
                </Grid>
              </Grid>
            </Grid>
          )
          break
        case 'switch':
          section = (
            <Grid item>
              <Grid
                container
                direction={'row'}
                justify={'center'}
                alignItems={'center'}
                spacing={2}>
                <Grid item>
                  <Switch
                    checked={authenticationStore.stayLoggedIn}
                    onChange={(e: any) =>
                      authenticationStore.handleLoginSelectorToggle()
                    }
                    color={'primary'}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: mainColors.mediumGrey }}>
                    Angemeldet bleiben
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
      }
      return (
        <React.Fragment key={`${title}-${index}`}>
          <ListItem
            style={{
              backgroundColor:
                title === 'button' || title === 'start' || title === 'start'
                  ? operationStore.isCurrentOperationOpen()
                    ? mainColors.blue
                    : mainColors.green
                  : '',
              color:
                title === 'button' || title === 'start' || title === 'start'
                  ? mainColors.white
                  : mainColors.black,
            }}>
            <Grid
              container
              justify={'center'}
              alignItems={'center'}
              direction={'row'}>
              {section}
            </Grid>
          </ListItem>
          {index < actionArray.length - 1 && <Divider />}
        </React.Fragment>
      )
    }
    function startJob() {
      if (
        operationStore.isCurrentOperationOpen() ||
        operationStore.currentOperationType === OperationStates.inSetup
      ) {
        operationStore.changeOperationType(OperationStates.inProgress)
      } else {
        if (operationStore.currentOperationType === OperationStates.inRework) {
          operationStore.openRework()
        } else operationStore.openConfirmation()
      }
    }
  })
)

export default ActionsTab
