import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import { mainColors } from '../../../styles/createMyTheme'
import {
  Check,
  Clear,
  Flag,
  List as ListIcon,
  Warning,
} from '@material-ui/icons'
import OperationStore from '../../../stores/OperationStore'
import { makeStyles } from '@material-ui/core/styles'
import ProgressBar from '../ProgressBar'
import { newConfirmation } from '../../../interfaces/IOperation'
import { Stores } from '../../../stores'
import QuantitySelector from './QuantitySelector'
import KeyPad from './KeyPad'
import AuthenticationStore from '../../../stores/AuthenticationStore'
import { useSnackbar } from 'notistack'

interface ConfirmationDialogProps {
  operationStore?: OperationStore
  authenticationStore?: AuthenticationStore
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.60em',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  button: {
    marginBottom: '20px',
    background: mainColors.greenGrad,
    width: '90%',
    alignSelf: 'center',
    color: 'white',
  },
  buttonDisabled: {
    marginBottom: '20px',
    background: mainColors.lightGrey,
    width: '90%',
    alignSelf: 'center',
    color: 'black',
  },
  caption: {
    color: mainColors.lightGrey,
  },

  list: {
    padding: 0,
  },

  circle: {
    width: '1.0em',
    height: '1.0em',
    borderRadius: '50%',
  },

  totalCircle: {
    background: mainColors.lightGrey,
  },

  balanceCircle: {
    background: mainColors.progressGrad,
  },

  rejectedCircle: {
    background: mainColors.rejectedGrad,
  },
}))

const listItems = ['balance', 'scrap', 'scrapReason']
const scrapReason = [
  '',
  'Fehlfunktion der Maschine',
  'Bedienungsfehler',
  'Fehlerhaftes Material (Komponente)',
  'Herstellungsfehler',
  'Materialfehler (Produkt)',
  'Produktionsfehler',
]

const ConfirmationDialog: React.FunctionComponent<any> = inject(
  Stores.operationStore,
  Stores.authenticationStore
)(
  observer((props: ConfirmationDialogProps) => {
    const { operationStore, authenticationStore } = props
    const [confirmation, setConfirmation] = useState(newConfirmation)
    const classes = useStyles()
    const [keypadOpen, setKeypadOpen] = useState(false)
    const [keypadNumberType, setKeypadNumberType] = useState('')
    const [extraConfirm, setExtraConfirm] = useState(false)
    return (
      <React.Fragment>
        <Button
          className={
            confirmation.finalConfirmation && !extraConfirm
              ? classes.buttonDisabled
              : classes.button
          }
          variant={'contained'}
          disabled={confirmation.finalConfirmation && !extraConfirm}
          onClick={() => {
            props.operationStore.addConfirmation(confirmation)
            operationStore.closeDialogs()
          }}>
          <Typography variant={'h6'}>
            {confirmation.finalConfirmation ? 'FINAL RÜCKMELDEN' : 'RÜCKMELDEN'}
          </Typography>
        </Button>

        <KeyPad
          open={keypadOpen}
          close={() => setKeypadOpen(false)}
          number={
            keypadNumberType === 'yield'
              ? confirmation.yield
              : confirmation.scrap
          }
          setNumber={
            keypadNumberType === 'yield' ? setManNumberYield : setManNumberScrap
          }
        />
        <Paper className={classes.root}>
          <Grid
            container
            justify={'center'}
            alignItems={'center'}
            direction={'column'}>
            <Grid
              container
              alignItems={'center'}
              justify={'space-between'}
              direction={'row'}>
              <Typography variant={'h5'}>RÜCKMELDUNG</Typography>
              <IconButton onClick={() => operationStore.closeDialogs()}>
                <Clear />
              </IconButton>
            </Grid>

            <Grid item style={{ width: '100%' }}>
              <Grid
                container
                justify={'center'}
                direction={'column'}
                spacing={2}>
                <Grid item>
                  <Grid
                    container
                    justify={'center'}
                    alignItems={'center'}
                    direction={'row'}
                    spacing={1}>
                    <Grid item>
                      <Grid
                        container
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1}>
                        <Grid item>
                          <div
                            className={`${classes.circle} ${classes.totalCircle}`}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.caption}
                            variant={'caption'}>
                            Geplant (
                            {operationStore.currentOperation.plannedAmount} ST)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1}>
                        <Grid item>
                          <div
                            className={`${classes.circle} ${classes.balanceCircle}`}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.caption}
                            variant={'caption'}>
                            Gutmenge (
                            {confirmation.yield +
                              operationStore.getSummedConfirmationYield()}
                            )
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <ProgressBar
                    variant={'determinate'}
                    value={getConfirmationProgressRatio()}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <Grid
                container
                justify={'center'}
                alignItems={'center'}
                direction={'row'}
                spacing={1}>
                <Grid item>
                  <Flag
                    style={{
                      color: confirmation.finalConfirmation
                        ? mainColors.green
                        : mainColors.lightGrey,
                    }}
                  />
                </Grid>

                <Grid item>
                  <Switch
                    color={'primary'}
                    value={confirmation.finalConfirmation}
                    onChange={() => {
                      setConfirmation({
                        ...confirmation,
                        finalConfirmation: !confirmation.finalConfirmation,
                      })
                    }}
                  />
                </Grid>

                <Grid item>
                  <Typography>Abschließende Rückmeldung</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <List className={classes.list}>
                  {listItems.map((title: string, index: number) =>
                    getListItem(title, index)
                  )}
                </List>
                {confirmation.finalConfirmation && (
                  <Grid
                    item
                    container
                    justify={'space-between'}
                    alignItems={'center'}
                    direction={'row'}>
                    <Grid item>
                      <Typography>Sind alle Mengen korrekt?</Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        color={'primary'}
                        checked={extraConfirm}
                        onChange={() => setExtraConfirm(!extraConfirm)}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          alignItems={'center'}
          justify={'center'}
          direction={'row'}
          style={{ backgroundColor: mainColors.ultraLightGrey }}>
          <Grid item>
            <Switch
              checked={authenticationStore.stayLoggedIn}
              onChange={(e: any) =>
                authenticationStore.handleLoginSelectorToggle()
              }
              color={'primary'}
            />
          </Grid>
          <Grid item>
            <Typography
              style={{ color: mainColors.mediumGrey }}
              variant={'caption'}>
              Angemeldet bleiben
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )

    function getListItem(title: string, index: number) {
      let section = <div />

      switch (title) {
        case 'balance':
          section = (
            <Grid
              container
              justify={'space-between'}
              alignItems={'center'}
              direction={'row'}>
              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  justify={'space-between'}
                  alignItems={'center'}
                  spacing={1}
                  wrap={'nowrap'}>
                  <Grid item>
                    <Check
                      fontSize={'large'}
                      style={{ color: mainColors.blue }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems={'flex-start'}
                    justify={'center'}
                    spacing={1}
                    direction={'column'}>
                    <Grid item>
                      <Typography variant={'caption'}>Gutmenge</Typography>
                    </Grid>
                    {showLabel() && (
                      <Grid item>
                        <Typography
                          variant={'caption'}
                          style={{ color: mainColors.error }}>
                          Unerwartete Menge
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <QuantitySelector
                quantity={confirmation.yield}
                add={addYield}
                subtract={subtractYield}
                openKeypad={() => {
                  setKeypadNumberType('yield')
                  setKeypadOpen(true)
                }}
              />
            </Grid>
          )
          break

        case 'scrap':
          section = (
            <Grid
              container
              justify={'space-between'}
              alignItems={'center'}
              direction={'row'}>
              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  justify={'space-between'}
                  alignItems={'center'}
                  spacing={1}
                  wrap={'nowrap'}>
                  <Grid item>
                    <Warning
                      fontSize={'large'}
                      style={{ color: mainColors.error }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant={'caption'}>Ausschuss</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <QuantitySelector
                quantity={confirmation.scrap}
                add={addScrap}
                subtract={subtractScrap}
                openKeypad={() => {
                  setKeypadNumberType('scrap')
                  setKeypadOpen(true)
                }}
                negative
              />
            </Grid>
          )
          break
        case 'scrapReason':
          section = (
            <Grid
              container
              alignItems={'center'}
              justify={'space-between'}
              direction={'row'}>
              <Grid item>
                <ListIcon
                  fontSize={'large'}
                  style={{ color: mainColors.error, marginTop: '20px' }}
                />
              </Grid>
              <Grid item style={{ width: '80%' }}>
                <FormControl
                  disabled={confirmation.scrap === 0}
                  style={{ width: '100%' }}>
                  <InputLabel id="scrap-reason-label">
                    Ausschussgrund
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={confirmation.scrapReason}
                    onChange={handleChangeReason}
                    color={'primary'}>
                    {scrapReason.map((item, index) => (
                      <MenuItem key={'reason' + index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )
      }

      return (
        <React.Fragment key={`confirmation-row-${index}`}>
          {index === 0 && <Divider />}
          <ListItem disableGutters>
            <Grid
              container
              justify={'center'}
              alignItems={'center'}
              direction={'row'}>
              {section}
            </Grid>
          </ListItem>
          {index < listItems.length - 1 && <Divider />}
        </React.Fragment>
      )
    }

    function getConfirmationProgressRatio() {
      const previousYield = operationStore.getSummedConfirmationYield()
      const currentSum = confirmation.yield + previousYield
      const number =
        currentSum >= operationStore.currentOperation.plannedAmount
          ? 100
          : currentSum
      return number === 100
        ? number
        : (number / operationStore.currentOperation.plannedAmount) * 100
    }

    function showLabel() {
      const actualAmount =
        confirmation.yield + operationStore.getSummedConfirmationYield()
      return (
        actualAmount > operationStore.currentOperation.plannedAmount ||
        (actualAmount < operationStore.currentOperation.plannedAmount &&
          confirmation.finalConfirmation)
      )
    }

    function getConfirmationRejectedRatio() {
      const previousScrap = operationStore.getSummedConfirmationScrap()
      const currentSum = confirmation.scrap + previousScrap
      const number =
        currentSum >= operationStore.currentOperation.plannedAmount
          ? 100
          : currentSum
      return number === 100
        ? number
        : (number / operationStore.currentOperation.plannedAmount) * 100
    }

    function addYield() {
      const newConfirmation = confirmation
      setConfirmation({ ...newConfirmation, yield: confirmation.yield + 1 })
    }

    function subtractYield() {
      const newConfirmation = confirmation
      if (newConfirmation.yield > 0)
        setConfirmation({
          ...newConfirmation,
          yield: newConfirmation.yield - 1,
        })
    }

    function addScrap() {
      const newConfirmation = confirmation
      setConfirmation({ ...newConfirmation, scrap: confirmation.scrap + 1 })
    }

    function subtractScrap() {
      const newConfirmation = confirmation
      if (newConfirmation.scrap > 0)
        setConfirmation({
          ...newConfirmation,
          scrap: newConfirmation.scrap - 1,
        })
    }

    function handleChangeReason(event) {
      setConfirmation({ ...confirmation, scrapReason: event.target.value })
    }

    function setManNumberYield(newNumber: number) {
      setConfirmation({ ...confirmation, yield: newNumber })
    }

    function setManNumberScrap(newNumber: number) {
      setConfirmation({ ...confirmation, scrap: newNumber })
    }
  })
)

export default ConfirmationDialog
