import RootStore from './RootStore'
import { action, observable } from 'mobx'
import AuthenticationService from '../services/AutheticationService'
import { getUser, IUser } from '../interfaces/IUser'
import ERoutes from '../interfaces/IGlobal'

export default class AuthenticationStore {
  rootStore: RootStore
  authenticationService = new AuthenticationService()

  @observable loggedIn = true
  @observable logInWithJwt = false
  @observable currentUser: IUser = getUser()
  @observable stayLoggedIn: boolean = true
  @observable isLoggingOut: boolean = false
  @observable scanningValid: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  setCurrentUser(user: IUser) {
    this.currentUser = { ...user }
  }

  @action handleLoginSelectorToggle() {
    this.stayLoggedIn = !this.stayLoggedIn
  }

  @action cancelAutoLogoff() {
    this.isLoggingOut = false
    this.stayLoggedIn = false
  }

  @action startAutoLogOff() {
    this.isLoggingOut = true
  }

  @action
  setUserProperties(authResponse: any) {
    this.loggedIn = true
    const tempUser = { ...this.currentUser }
    Object.assign(tempUser, authResponse.user)
    this.currentUser = tempUser
    this.rootStore.routerStore.navigateTo(ERoutes.HOME)
  }

  @action
  async JWTLogin() {
    const jwt = window.localStorage.getItem('jwt')
    if (jwt) {
      this.rootStore.setLoginLoading(true)
      try {
        const auth = await this.authenticationService.authenticateWithToken(jwt)
        this.setUserProperties(auth)
        this.logInWithJwt = false
        this.rootStore.setLoginLoading(false)
      } catch (e) {
        this.logInWithJwt = false
        this.rootStore.setLoginLoading(false)
        return e
      }
    } else this.logInWithJwt = false
    return null
  }

  @action
  login(pin: string, scan?: boolean) {
    /*    this.rootStore.setLoginLoading(true)
    try {
      const response = await this.authenticationService.authenticate(
        email,
        password
      )
      this.setUserProperties(response)
      this.rootStore.setLoginLoading(false)
    } catch (e) {
      this.rootStore.setLoginLoading(false)
      throw e
    }*/
    if (pin === '1371') {
      if (scan) {
        this.rootStore.playSuccessSound()
        this.scanningValid = true
      }
      let user = getUser()
      user._id = '1371'
      user.username = 'alex.schmitt'
      user.firstname = 'Alex'
      user.username = 'Schmitt'
      this.setUserProperties(user)
    } else {
      if (scan) {
        this.scanningValid = false
        this.rootStore.playErrorSound()
      }
      this.rootStore.snackbarStore.openSnackbar(
        'Personalnummer ungültig.',
        'error'
      )
    }
  }

  @action
  recoverPassword(recoveryEmail: string) {
    this.authenticationService.sendRecoveryPassword(recoveryEmail)
  }

  @action
  isUserLoggedAndCached() {
    const user = JSON.parse(localStorage.getItem('user') as any)
    const jwt = localStorage.getItem('jwt') as any
    return !!user && !!jwt && this.loggedIn
  }

  @action
  cleanLoginData() {
    this.authenticationService.removeUser()
    this.currentUser = getUser()
    this.logInWithJwt = true
    this.loggedIn = false
    this.rootStore.routerStore.navigateTo(ERoutes.LOGIN)
  }
}
