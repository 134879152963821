// User
export interface IUser {
  _id: string
  username: string
  firstname: string
  lastname: string
}

export const User: IUser = {
  _id: '',
  username: '',
  firstname: '',
  lastname: '',
}

export const getUser = (): IUser => {
  return {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
  }
}
