import AuthenticationStore from './AuthenticationStore'
import { action, observable } from 'mobx'
import RouterStore from './RouterStore'
import OperationStore from './OperationStore'
import SnackbarStore from './SnackbarStore'
import scanStates from '../interfaces/IScan'

export default class RootStore {
  authenticationStore: AuthenticationStore
  routerStore: RouterStore
  operationStore: OperationStore
  snackbarStore: SnackbarStore
  loadingRegistry: {
    [key: string]: boolean
  } = {}
  @observable anyLoading = false
  @observable loginLoading = false
  @observable scanState = scanStates.STOPPED
  @observable scanSound = 'success'

  constructor() {
    this.authenticationStore = new AuthenticationStore(this)
    this.routerStore = new RouterStore(this)
    this.operationStore = new OperationStore(this)
    this.snackbarStore = new SnackbarStore(this)
  }

  @action
  setLoginLoading(loading: boolean) {
    this.loadingRegistry['login'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.loginLoading = loading
  }

  @action stopPlaying() {
    this.scanState = scanStates.STOPPED
  }

  @action playSuccessSound() {
    this.scanSound = 'success'
    this.scanState = scanStates.PLAYING
  }
  @action playErrorSound() {
    this.scanSound = 'error'
    this.scanState = scanStates.PLAYING
  }
}
