import user from './user.json'
import operations from './operations.json'
import { IOperation } from '../../interfaces/IOperation'
import { IUser } from '../../interfaces/IUser'

interface IMockdata {
  operations: IOperation[]
  user: any
}
const mockData: IMockdata = {
  operations,
  user,
}

export default mockData
