export const commonFunctions = {
  handleResponse,
  getEndpoint,
}

function getEndpoint() {
  return process.env.REACT_APP_ENDPOINT_ENV
}

function handleResponse(response: any) {
  return response.text().then((text: string) => {
    const data = text && IsJsonString(text) ? JSON.parse(text) : text
    if (!response.ok) {
      const error = new Error(response.status)
      return Promise.reject(error)
    }

    return data
  })

  function IsJsonString(str: string) {
    try {
      const o = JSON.parse(str)

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // But... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsy, so this suffices:
      if (o && typeof o === 'object') {
        return o
      }
    } catch (e) {
      return false
    }

    return true
  }
}
