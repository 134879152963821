import RootStore from './RootStore'
import { createBrowserHistory } from 'history'
import ERoutes from '../interfaces/IGlobal'
import { action, observable } from 'mobx'

export const history = createBrowserHistory()

class RouterStore {
  rootStore: RootStore
  @observable leaveModalOpen: boolean = false
  @observable valuesChanged: boolean = false
  navigateAway = false
  initialPath: ERoutes | string = ERoutes.LOGIN
  handleLeave: (save: boolean) => any = () => {}

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  setLeaveModalOpen(open: boolean) {
    this.leaveModalOpen = open
  }

  @action
  setValuesChanged(valuesChanged: boolean) {
    this.valuesChanged = valuesChanged
  }

  @action
  setHandleLeave(handleLeave: (save: boolean) => any) {
    this.handleLeave = handleLeave
  }

  @action
  navigateTo(path: ERoutes | string, params?: any) {
    if (window.location.pathname !== path) {
      this.navigateAway = true
      if (!this.valuesChanged) history.push({ pathname: path, state: params })
      else {
        this.initialPath = path
        this.leaveModalOpen = true
      }
    }
  }
}

export default RouterStore
