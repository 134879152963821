import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  Dialog,
  SwipeableDrawer,
  Tab,
  Tabs,
  useTheme,
} from '@material-ui/core'
import SwipeableView from 'react-swipeable-views'
import { mainColors } from '../../styles/createMyTheme'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DetailsTab from './DetailsTab'
import FeedbackTab from './FeedbackTab'
import OperationStore from '../../stores/OperationStore'
import { inject, observer } from 'mobx-react'
import { Stores } from '../../stores'
import ConfirmationDialog from './ActionDrawerDetails/ConfirmationDialog'
import PauseDialog from './ActionDrawerDetails/PauseDialog'
import ReworkDialog from './ActionDrawerDetails/ReworkDialog'
import ActionsTab from './ActionsTab'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ padding: 0 }} p={1}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: mainColors.white,
    padding: 0,
    width: '100%',
  },
  blueTab: {
    background: mainColors.blue,
  },
  greenTab: {
    background: mainColors.green,
  },
}))

interface ProductionDetailsProps {
  operationStore?: OperationStore
}

const ProductionDetails: React.FunctionComponent<any> = inject(
  Stores.operationStore
)(
  observer((props: ProductionDetailsProps) => {
    const { operationStore } = props
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = useStyles(theme)
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
      setValue(newValue)
    }

    const handleChangeIndex = (index) => {
      setValue(index)
    }
    const [isOpen, setOpen] = useState<boolean>(true)
    useEffect(() => {
      setOpen(operationStore.isCurrentOperationOpen())
    }, [operationStore.currentOperationType])

    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                background: isOpen ? mainColors.blue : mainColors.green,
              },
            }}
            textColor={'inherit'}
            variant="fullWidth">
            <Tab label={t('Aktionen').toUpperCase()} {...a11yProps(0)} />
            <Tab label={t('Details').toUpperCase()} {...a11yProps(1)} />
            <Tab
              label={`${t('Rückm.').toUpperCase()} (${
                operationStore.currentOperation.confirmations.length
              })`}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <SwipeableView
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}>
          <TabPanel
            className={classes.tab}
            value={value}
            index={0}
            dir={theme.direction}>
            <ActionsTab />
          </TabPanel>
          <TabPanel
            className={classes.tab}
            value={value}
            index={1}
            dir={theme.direction}>
            <DetailsTab operation={operationStore.currentOperation} />
          </TabPanel>
          <TabPanel
            className={classes.tab}
            value={value}
            index={2}
            dir={theme.direction}>
            <FeedbackTab
              confirmations={operationStore.currentOperation.confirmations}
            />
          </TabPanel>
        </SwipeableView>
      </div>
    )
  })
)
export default ProductionDetails
